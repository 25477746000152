main{
  min-height: 80vh;
}

h3{
  padding: 1rem 0;
}

h1{
  font-size: 1.8rem;
  padding: 1rem 0;
  /*color: #333;*/
}

h2 {
  font-size: 1.4rem;
    padding: 0.5rem 0;
  /*color: #333;*/
}

.rating span{
  margin: 0.1rem;
}

.wrapper{
  overflow: hidden;
}

.wrapper img{
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
}

.wrapper:hover img{
  scale: 120%;
}

.styles-module_whatsappButton__tVits {
  background-color: rgb(11, 96, 21) !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px 0px;
}